::-webkit-scrollbar {
  width: 8px; /* Genişliği buradan ayarlayabilirsiniz */
  height: 8px; /* Yatay scrollbar için yükseklik */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track rengi */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c4dcef; /* Scrollbar rengi */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Thumb etrafında boşluk */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b0d4f1; /* Hover durumunda renk değişimi */
}

/* Firefox için scrollbar stilleri */
* {
  scrollbar-width: thin; /* İnce scrollbar */
  scrollbar-color: #c4dcef #f1f1f1; /* Thumb ve Track renkleri */
}
