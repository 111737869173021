@import "scrollbar.css";
@import "font.css";
@import "antdCustom.css";
@import "tableSpin.css";

body {
  margin: 0;
  padding: 0;
}

.custom-drawer .ant-drawer-header {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}

.colored-card {
  position: relative;
  color: white;
  overflow: hidden;
}

.colored-card::before,
.colored-card::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 22.07%,
    rgba(255, 255, 255, 0.15) 83.21%
  );
  transform: rotate(38deg);
  top: 78px;
  left: 30%;
}

.colored-card::after {
  transform: rotate(38deg);
  top: -50px;
  left: 40%;
}

.rp-slide-effect-card {
  position: relative;
  overflow: hidden;
}

.rp-slide-effect-card:after,
.rp-slide-effect-card:before {
  content: "";
  position: absolute;
  top: -84px;
  right: 0px;
  width: 80px;
  height: 400px;
  background: white;
  transform: rotate(45deg);
  animation: slide 0.8s ease-in-out forwards;
  animation-delay: 0s;
}

@keyframes slide {
  0% {
    right: -50px;
  }
  100% {
    right: calc(100% + 48px);
    opacity: 0;
  }
}
